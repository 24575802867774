<template>
    <div>
        <alliances-component/>
        <footer-component/>
    </div>
</template>

<script>
    import AlliancesComponent from "@/components/alliances/AlliancesComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";

    export default {
        name: "Alliances",
        title: "Alianzas | Turismo BC",
        components: {
            FooterComponent,
            AlliancesComponent
        }
    }
</script>

<style scoped>

</style>