<template>
    <v-container class="fill-height text-left pa-0 ma-0" fluid>
        <v-row justify="start">
            <v-col cols="12">
                <v-card class="d-flex fill-height d-flex fluid pa-0 ma-0" flat tile width="100vw">
                    <v-scroll-x-transition appear>
                        <v-img
                            height="200"
                            max-height="200"
                            position="center"
                            src="@/assets/images/bg-breadcrumbs.jpg"
                            transition="slide-x-transition"
                            width="100%">
                            <v-container
                                class="fill-height align-end"
                                fluid>
                                <v-row align="end" justify="start">
                                    <v-col cols="12">
                                        <div class="white--text">
                                            <span
                                                class="white--text pl-5 text-h5 text-md-h3 font-weight-black">
                                                Alianzas estratégicas...
                                            </span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-breadcrumbs :items="items" dark>
                                            <template v-slot:divider>
                                                <v-icon>mdi-arrow-right</v-icon>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-breadcrumbs-item
                                                    :disabled="item.disabled"
                                                    :href="item.href">
                                                        <span class="white--text">
                                                            {{ item.text }}
                                                        </span>
                                                </v-breadcrumbs-item>
                                            </template>
                                        </v-breadcrumbs>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-img>
                    </v-scroll-x-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <div class="boxdivider"></div>
        </v-row>
        <v-row justify="center">
            <div class="d-flex fill-height fluid pt-8 pb-8 px-5 px-md-10" style="width: 100%">
                <v-container class="fill-height" fluid>
                    <v-row align="center" justify="start">
                        <v-col
                            v-for="(item, index) in alliances"
                            :key="index"
                            class="d-flex child-flex"
                            cols="12" md="4" sm="6">
                            <div class="fill-height">
                                <!-- v-if="item.pictures[0] != null" -->
                                <v-card
                                        class="rounded-lg zoom-img"
                                        elevation="10"
                                        height="auto"
                                        hover
                                        outlined
                                        width="100%"
                                        v-on:click="showProfile(item.id)">
                                    <v-img
                                        :lazy-src="require('@/assets/images/no-image.jpg')"
                                        :src="item.pictures[0] != null ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                        class="white--text align-end justify-start zoom-image"
                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                                        height="350"
                                        position="center">
                                        <template v-slot:placeholder>
                                            <v-row
                                                align="center"
                                                class="fill-height ma-0"
                                                justify="center">
                                                <v-progress-circular
                                                    color="grey lighten-5"
                                                    indeterminate>
                                                </v-progress-circular>
                                            </v-row>
                                        </template>

                                        <div class="white--text mx-2">
                                            <p class="text-left font-weight-light text-h5">
                                                {{ item.name }}
                                            </p>
                                        </div>
                                    </v-img>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-row>
        <v-row v-if="alliances.length === 0" class="ma-10" justify="center">
            <div>
                No hay Alianzas en el catálogos...
            </div>
        </v-row>
    </v-container>
</template>

<script>
    import alliancesService from "@/providers/AlliancesService";

    export default {
        name: "AlliancesComponent",
        data: () => ({
            items: [
                {
                    text: 'Home',
                    disabled: false,
                    href: '/homepage',
                },
                {
                    text: 'Alianzas',
                    disabled: true,
                    href: '/about',
                }
            ],
            selectedId: 0,
            alliances: [],
            transparent: "rgba(255, 255, 255, 0)",
        }),
        methods: {
            getAlliances() {
                alliancesService.getViewList().then(record => {
                    this.alliances = record.value;
                    // console.log("Alianzas: ", this.alliances);
                });
            },
            showProfile(idAlliance) {
                this.selectedId = idAlliance;
                this.$router.push({
                    name: "AllianceProfile",
                    params: {
                        id: idAlliance,
                    }
                });
            }
        },
        mounted() {
            this.alliances = [];
            this.getAlliances();
        },
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }

    .backcolor {
        background-color: transparent;
    }

    .panel-filter-color {
        background: rgba(255, 255, 255, 0.767) 100%;
    }

    .boxdivider {
        background-color: transparent;
        width: 100px;
        height: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }

    .zoom-img {
        overflow: hidden;
        border-radius: 10px;
    }

    .zoom-img > img {
        object-fit: cover;
        transition: all .3s ease-in-out;
    }

    .zoom-img:hover > img {
        transform: scale(1.05);
    }

    .zoom-image {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        /* VERZÖGERUNG */
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        overflow: hidden;
    }

    .zoom-image:hover {
        -webkit-transform: scale(1.07);
        -moz-transform: scale(1.07);
        -ms-transform: scale(1.07);
        -o-transform: scale(1.07);
        transform: scale(1.07);
    }
</style>